import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCommentList from './components/CommentList.vue'
import VueComments from './components/Comments.vue'
import VuePage from './components/Page.vue'

Vue.config.productionTip = false

Vue.component('VueCommentList', VueCommentList)
Vue.component('VueComments', VueComments)
Vue.component('VuePage', VuePage)

// 请求接口
import axios from './httpConfig/index.js';
Vue.prototype.$ajax = axios;

// https://www.jianshu.com/p/f592b59e9ee9
// 引入VeeValidate表单验证的规则配置文件
import './util/veevalidate';

// 注册VeeValidate全局组件
import { ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate';
// 全局设置交互模式（触发验证的方式）------也可以在ValidationProvider组件中添加mode="Eager"单独设置组件
setInteractionMode('eager');
// 全局注册组件
Vue.component('ValidationProvider', ValidationProvider);
// 在提交表单的时候需要对整个表单进行验证，这个时候需要用到ValidationObserver组件
Vue.component('ValidationObserver', ValidationObserver);

// 公共方法
import util from './util/util'
Vue.use(util);

// 设置标签页
router.beforeEach((to, from, next) => {
  // 路由发生变化修改页面的title
  const str = ' | 前沿技术社区'
  if (to.meta.title) {
    document.title = to.meta.title + str
  } else {
    if (to.name == 'ArticleCreateUpdate') {
      if (to.params.articleId == 0) document.title = '创建文章' + str
      else document.title = '编辑文章' + str
    }
  }
  next();
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
