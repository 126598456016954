<template>
  <header class="header">
    <div class="container flex-three">
      <div class="menu-left">
        <div @click="showNavHandler" class="menu-icon cursor"><i class="fa fa-bars"></i></div>
        <nav :class="{'open': isShow}">
          <ul>
            <li @click="goPageHandler" :class="{'active': $route.name == 'Home'}"><router-link :to="{name: 'Home', params: {page: 1}}">首页</router-link></li>
            <li><a target="_blank" href="http://www.geekfun.website">GeekFun</a></li>
            <li><a target="_blank" href="https://code.artech.cn">实训平台</a></li>
            <li @click="goPageHandler" :class="{'active': $route.name == 'BuyBook'}"><router-link :to="{name: 'BuyBook'}">购书</router-link></li>
            <li @click="goPageHandler"><router-link :to="{name: 'ArticleDetail', params: { articleId: 143 }}">读者留言</router-link></li>
          </ul>
        </nav>
      </div>
      <div class="logo"><a href="/"><img src="../assets/logo-website.png" alt=""></a></div>
      <div class="category-control cursor" @click="showSideBarHandler">目录</div>
      <div class="mask" v-show="isShow" style="z-index: 10;" @click="hideNavHandler"></div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    
  },
  data() {
    return {
      isShow: false, //展示nav菜单
      isShowSideBar: false //展示侧边栏
    } 
  },
  methods: {
    // 跳转页面
    goPageHandler() {
      this.isShow = false
      this.isShowSideBar = false
      this.$emit('showSideBar', this.isShowSideBar)
    },
    // 收起菜单
    hideNavHandler() {
      this.isShow = false
    },
    // 是否展开菜单
    showNavHandler() {
      this.isShow = !this.isShow
    },
    // 是否展示侧边栏（分类）
    showSideBarHandler() {
      this.isShowSideBar = !this.isShowSideBar
      this.$emit('showSideBar', this.isShowSideBar)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
header {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid $lineColor;
  position: fixed;
  z-index: 100;
  background: #fff;

  & > div {
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .menu-icon {
    font-size: 20px;
  }

  .logo {
    width: 150px;

    a {
      display: block;
    }

    img {
      width: 100%;
    }
  }
}

.category-control {
  font-size: 16px;
}

nav {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  padding: 0 20px;
  height: 0;
  overflow: hidden;
  transition: height 0.5s;
  border-bottom: none;
  z-index: 99;
  background: #fff;

  &.open {
    height: 163px;
    box-shadow: 0px 2px 7px $lineColor;
  }

  li {
    line-height: 40px;
    border-bottom: 1px solid $lineColor;

    &:last-child {
      border-bottom: none;
    }

    &.active a {
      color: $themeColor;
    }

    a {  
      display: block;    
      color: $secondaryColor;
    }
  }
}

@media screen and (min-width: 768px) {
  .header {
    height: 70px;

    & > div {
      padding: 0 20px;
      box-sizing: border-box;
      justify-content: flex-end !important;
      flex-direction: row-reverse;
    }

    .logo {
      width: 260px;
    }

    .menu-icon {
      display: none;
    }
  }

  nav {
    position: inherit;
    height: auto;
    margin-left: 40px;
    

    ul {
      display: flex;
      align-items: center;
    }

    li {
      border: none;
      line-height: normal;
      font-size: 16px;

      &+li {
        margin-left: 25px;
      }

      &:hover {
        a {
          color: $themeColor;
        }
      }
    }
  }

  .category-control {
    display: none;
  }
}
</style>
