<template>
  <li class="comment-item">
    <div class="author-info flex items-center">
      <div class="avatar" :style="{'background-color': bgColor}">{{item.userName.charAt(0).toUpperCase()}}</div>
      <div class="info">
        <b>{{item.userName}}</b>
        <time>{{$formatDate(true, item.createTime)}}</time>
      </div>
    </div>
    <div class="content" v-html="formatContent(item.content)"></div>
    <div class="reply-btn" @click="replyHandler(item.commentId)"><a href="javascript:void(0);">回复</a></div>
    <div :id="'reply_' + item.commentId">
      <div class="comments reply-form" v-if="item.commentId == $store.state.currentCommentId">
        <vue-comments :isReply="true" :parentId="item.commentId" @refreshComments="refreshComments" />
      </div>
    </div>

    <vue-comment-list :comments="item.replies" v-if="item.replies && item.replies.length > 0" />
  </li>
</template>

<script>
export default {
  name: 'CommentItem',
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  inject: ['getComments'],
  data() {
    return {
      isShowComments: false,
      avatarBgArr: ['#cf4646', '#35bebb', '#5f8bd5', '#26d692']
    }
  },
  computed: {
    bgColor() {      
      return this.avatarBgArr[this.item.userName.length % 4]
    }
  },
  methods: {
    refreshComments() {
      this.getComments()
    },
    replyHandler(id) {
      // this.currentId = id
      this.$store.commit('setCurrentCommentId', id)
      // this.isShowComments = true
    },
    formatContent(content) {
      var text = content.replace(/\r\n\r\n/g, '</p><p>').replace(/\n\n/g, '</p><p>').replace(/\n/g, '</p><p>')
      return `<p>${text}</p>`
    }
  }
}
</script>

<style lang="scss">
.comment-item {
  margin: 12px 0;
  padding: 1.75em 0 1em 0;

  &+.comment-item {
    border-top: 1px solid $darkLineColor;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .author-info {
    .avatar {
      text-align: center;
      line-height: 37px;
      border-radius: 50%;
      overflow: hidden;
      width: 37px;
      height: 37px;
      margin-right: 14px;
      color: #fff;
      font-size: 16px;
    }

    .info {
      time {
        display: block;
        color: #686868;
        font-size: 12px;
      }
    }
  }

  .content {
    margin: 15px 0;
    line-height: 1.75;
  }

  .reply-btn {
    border: 1px solid $darkLineColor;
    display: inline-block;
    border-radius: 2px;
    font-size: 13px;
    line-height: 1;
    padding: 0.5em 0.5em 0.4em;
    cursor: pointer;

    a {
      color: $themeColor;

      &:hover {
        text-decoration: underline;
      }
    }
  }

}
</style>
