<template>
    <div class="file-upload">
        <input
            type="file"
            class="form-control"
            ref="fileEle"
            @change="uploadFile($event)"
        />
        <ul class="file-list" v-if="fileList.length > 0">
            <li v-for="item in fileList" :key="item.key">
                <a :href="item.file_path" target="_blank">{{item.file_name}}</a>
                <button @click="deleteFile(item)" class="btn">删除</button>
                <button @click="insertFile(item)" class="btn">插入</button>
            </li>
        </ul>
    </div>
</template>
<script>
  import axios from 'axios';

  export default {
    name: 'FileUpload',
    data() {
      return {
        fileList: []
      };
    },
    methods: {
      remoteItem(array, item, key) {
        const index = array.findIndex(o => o[key] === item[key]);
        if (index !== -1) array.splice(index, 1);
      },
      deleteFile(item) {
        this.remoteItem(this.fileList, item, 'key')
        this.$refs.fileEle.value = ''
      },
      insertFile(item) {
        this.$emit('insert', item);
      },
      uploadFile(obj) {
        const _this = this;
        const file = obj.target.files[0];
        if (file) {
          const formData = new window.FormData();
          formData.append('file', file);
          axios({
            method: 'post',
            url: process.env.VUE_APP_UPLOAD_URL,
            data: formData,
            headers: {
              'Content-Type':
                'multipart/form-data; boundary=----WebKitFormBoundaryn6qdFAbQis3EN5pA'
            }
          })
            .then(function(response) {
              console.log(response);
              _this.fileList.push(response.data)
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          alert('上传失败');
        }
      }
    }
  };
</script>

<style scoped>
.file-list .btn {
    margin-left: 5px;
    cursor: pointer;
}
.file-list a {
    color: #666;
}
</style>