<template>
  <div class="home">
    <!-- 一进入显示："正在加载相关文章~"，没有文章就显示："没有数据~"，反之显示文章列表 -->
    <div class="post-loading" v-show="isLoading">{{isLoading == 'loading' ? '正在加载相关文章~' : '没有文章~'}}</div>
    <div class="article-list">
      <ul>
        <li class="article-item cursor" v-for="item in articles" :key="item.articleId">
          <p class="tag"><span v-for="it in item.tags" :key="it.tagId">{{it.name}}</span></p>
          <div class="title"><router-link :to="{name: 'ArticleDetail', params: { articleId: item.articleId }}">{{item.subject}}</router-link></div>
          <div class="metas">
            <i class="fa fa-clock-o"></i> {{ $formatDate(false, item.updateTime) }}
            <i class="fa fa-comments-o"></i> {{item.commentCount}}
          </div>
          <div class="info" v-if="selector.tagId > 0">{{item.abstract}}</div>
          <div v-else class="info quill-editor us-editor">
            <div class="ql-container ql-snow">
              <div class="ql-editor">
                <div v-html="item.content"></div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <vue-page v-if="articles.length > 0" :page="selector.page" :totalPage="totalPage" @page-handler="pageInit" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      isLoading: 'loading',
      articles: [],
      selector: {
        tagId: null,
        page: 1
      },
      pageSize: 10,
      totalPage: 1
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.selector.tagId = this.$route.params.tagId
    this.selector.page = this.$route.params.page || 1
    this.getArticles(this.selector);
    if (this.selector.tagId != null) {
      document.title = '首页 | 前沿技术社区'
    }
  },
  watch: {
    $route(to) {
      this.selector = to.params;
      if (to.params.tagId != null) {
        document.title = '首页 | 前沿技术社区'
      }
    },
    selector: {
      handler(value) {
        console.log(value)
        this.articles = [];
        // this.isLoading = true;
        if (value.page) {
          this.$router.push({
            name: 'Home',
            params: value
          });
          this.getArticles(value);
          window.scrollTo(0, 0);
        }
      },
      deep: true
    }
  },
  methods: {
    getArticles(params) {
      this.isLoading = 'loading'
      params.pageSize = this.pageSize
      const _this = this
      this.$ajax.get('/api/article/list', params, 2, response => {
        if (response.status >= 200 && response.status < 300) {
          console.log(response);
          // 
          if (response.data.articles.length <= 0) { _this.isLoading = "nothing" }
          else _this.isLoading = null

          _this.articles = response.data.articles
          
          _this.totalPage = response.data.pagination && response.data.pagination.pageCount;
          _this.selector.page = response.data.pagination && response.data.pagination.pageNumber;

        } else {
          console.log(response.message);
          _this.isLoading = null
        }
      });
    },
    // 点击分页
    pageInit(page) {
      this.selector.page = page;
    }
  }
}
</script>

<style lang="scss">
a { color: $themeColor; }

.home {
  padding: 20px;

  .article-list li.article-item {
    padding: 30px 0;
    border-bottom: 1px solid #333;

    &:last-child {
      border-bottom: none;
    }

    &:first-child {
     padding-top: 0;
    }

    .title {
      a {
        font-size: 17px;
        color: $themeColor;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .metas {
      margin: 5px 0 15px;
      color: $lightDefaultColor;
      font-size: 12px;

      i {
        margin-right: 4px;

        & + i {
          margin-left: 12px;
        }
      }
    }

    .info {
      line-height: 1.6;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

@media screen and (min-width: 768px) {
  .home {
    .article-list li.article-item {
      .title a {
        font-size: 20px;
      }
    }
  }
}
</style>