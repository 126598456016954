<template>
  <ul class="comments-list">
    <vue-comment-item v-for="item in comments" :key="item.id" :item="item" />
  </ul>
</template>

<script>
import VueCommentItem from './CommentItem.vue'

export default {
  name: 'CommentList',
  components: {
    VueCommentItem
  },
  props: {
    comments: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
.comments-list {
  .comments-list {
    margin-left: 50px;
  }
}
</style>