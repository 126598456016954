import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import BuyBook from '../views/BuyBook.vue'
import ArticleCreateUpdate from '../views/ArticleCreateUpdate.vue'

Vue.use(VueRouter)


const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
  {
    path: '/page/:page(\\d+)?/:tagId(\\d+)?',
    name: 'Home',
    component: Home,
    alias: ['/']
  },
  {
    path: '/article/details/:articleId',
    name: 'ArticleDetail',
    component: () => import('../views/ArticleDetail.vue')
  },
  {
    path: '/:slug(\\d{8}.[\\-a-zA-Z0-9]+.html)',
    name: 'ArticleDetail2',
    component: () => import('../views/ArticleDetail.vue')
  },
  {
    path: '/buy',
    name: 'BuyBook',
    component: BuyBook,
    meta: {
      title: '购书'
    }
  },
  {
    path: '/post/create-update/:articleId(\\d+)',
    name: 'ArticleCreateUpdate',
    component: ArticleCreateUpdate,
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
