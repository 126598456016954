import axios from 'axios';
import Qs from 'qs';

// let context = null;

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
});

function apiAxios(method, url, params, flag = 2, response) {
  // 请求接口
  requestHandler(method, url, params, flag, response);
}

function requestHandler(method, url, params, flag, response) {
  const headers = {
    'Content-Type': 'application/json'
  };

  const accessToken = localStorage.getItem('accessToken');

  if (accessToken && accessToken !== '') {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  var p = {
    headers: headers,
    method: method,
    url: url,
    data: method === 'POST' || method === 'PUT' ? params : null,
    params: method === 'GET' || method === 'DELETE' ? params : null
  };

  if (flag === 1) {
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
    p.transformRequest = [
      function(params) {
        return Qs.stringify(params);
      }
    ];
  }

  if (flag === 3) {
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
    p.responseType = 'blob';
  }

  http(p)
    .then(function(res) {
      console.log(res)
      response(res);
    })
    .catch(function(err) {
      console.log(err)
      Promise.reject(err.response.data).catch(function(res) {
        response(res);
        console.log(res)
        console.log(response(res))
        // 状态码为401，表示token已过期
        // token过期，并且不是登录页，就跳转到登录页
        // if (res.status == 401 && context.$route.name != 'Login') {
        // if (!res && context.$route.name != 'Login') {
        //   localStorage.setItem('isLogin', false)
        //   context.$router.push('/login')
        // }
      });
      // response(Promise.reject(err));
    });
}

/* 请求接口失败后，判断用户登录状态:
  1. 未登录：如果是未登录过，直接访问，需要登录的接口直接弹登录框，登陆成功，将accessToken存到localStorage中
  2. 已登录：正常操作
  3. 过期了：如果accessToken过期了，请求刷新token接口，刷新成功，继续正常操作，如果刷新失败，弹出登录框
*/

// 注：PUT请求默认会发送两次请求，第一次预检请求不含参数，所以后端不能对PUT请求地址做参数限制

export default {
  // initContext(vue) {
  //   context = vue;
  // },
  get: function(url, params, flag, response) {
    return apiAxios('GET', url, params, flag, response);
  },
  post: function(url, params, flag, response) {
    return apiAxios('POST', url, params, flag, response);
  },
  put: function(url, params, flag, response) {
    return apiAxios('PUT', url, params, flag, response);
  },
  delete: function(url, params, flag, response) {
    return apiAxios('DELETE', url, params, flag, response);
  }
};
