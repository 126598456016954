<template>
  <div class="footer">
    <div class="container">
      <div class="l flex-three">
        <div class="f-left flex-three">
          <a href="/"><img src="../assets/footer-logo.svg" alt=""></a>
          <span>YOUR SOFTWARE PARTNER</span>
        </div>
        <ul class="flex">
          <li><router-link :to="{name: 'Home', params: {page: 1}}">首页</router-link></li>
          <li><a target="_blank" href="http://www.geekfun.website">GeekFun</a></li>
          <li><a target="_blank" href="https://code.artech.cn">实训平台</a></li>
          <li @click="goPageHandler('BuyBook')"><a href="javascript:void(0);">购书</a></li>
          <li><router-link :to="{name: 'ArticleDetail', params: { articleId: 143 }}">读者留言</router-link></li>
        </ul>
      </div>
      <p>© 2022 Artech. All rights reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    // 跳转页面
    goPageHandler(name) {
      this.$router.push({
        name: name
      })
      window.scrollTo(0,0);
    },
  }
}
</script>

<style lang="scss">
.footer {
  background: rgb(49, 50, 71);
  padding: 25px 12px;
  margin-top: 20px;
  
  .l {
    justify-content: center;
  }
  

  .f-left {
    font-size: 10px;
    font-weight: 100;
    line-height: 1.7;
    color: #fff;

    a {
      height: 24px;
    }

    span {
      margin-left: 10px;
    }
  }

  p, ul {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .footer {
    padding: 30px 20px;

    .l {
      justify-content: space-between;
    }

    p {
      display: block;
      opacity: 0.4;
      color: #fff;
      line-height: 1.7;
      text-align: right;
      height: 30px;
      margin-top: 15px;
    }

    .f-left {
      font-size: 15px;
      span {
        margin-left: 20px;
      }
    }

    ul {
      display: flex;

      li {
        font-size: 16px;

        &+li {
          margin-left: 30px;
        }
      }
      a {
        color: #fff; 

        &:hover {
          color: $themeColor;
        }
      }
    }
  }
}
</style>