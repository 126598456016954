<template>
  <div id="sidebar-control" :class="['post-sidebar', {'active': isShowSideBar}]">
    <h2>分类目录</h2>
    <ul id="sidebar-categories">
      <li v-for="item in categories" :key="item.tagId">
        <h3>
          <router-link v-if="item.tagId" :to="{name: 'Home', params: { page: 1, tagId: item.tagId }}">{{item.name}}</router-link>
          <router-link v-else-if="item.articleId" :to="{name: 'ArticleDetail', params: { articleId: item.articleId }}">{{item.name}}</router-link>
          <span v-else>{{item.name}}</span>
        </h3>
        <ul v-if="item.children">
          <li v-for="child in item.children" :key="child.id">
            <h3>
              <router-link v-if="child.isList" :to="{name: 'Home', params: { page: 1, tagId: child.tagId }}">{{child.name}}</router-link>
              <router-link v-else :to="{name: 'ArticleDetail', params: { articleId: child.articleId }}">{{child.name}}</router-link>
            </h3>
            <ul>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SideBar',
  props: {
    isShowSideBar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      categories: [
        { 
          tagId: 5, 
          name: 'HTML5 + CSS3 Web开发案例教程', 
          isList: true,
          children: [
            { tagId: 6, name: 'HTML5 + CSS3基础', isList: true  },
            { tagId: 7, name: 'HTML5 + CSS3进阶', isList: true  },
            { tagId: 8, name: 'HTML5 + CSS3综合案例', isList: true  },
          ] 
        },
        { 
          tagId: 13, 
          name: 'JavaScript Web开发案例教程', 
          isList: true,
          children: [
            { tagId: 14, name: 'JavaScript基础', isList: true  },
            { tagId: 15, name: 'JavaScript进阶', isList: true  },
            { tagId: 16, name: 'JavaScript综合案例', isList: true  },
          ] 
        },
        { 
          tagId: 1, 
          name: 'Bootstrap Web开发案例教程', 
          isList: true,
          children: [
            { tagId: 2, name: 'Bootstrap基础', isList: true  },
            { tagId: 3, name: 'Bootstrap进阶', isList: true  },
            { tagId: 4, name: 'Bootstrap综合案例', isList: true  },
          ] 
        },
        { 
          tagId: 17, 
          name: 'Vue.js Web开发案例教程', 
          isList: true,
          children: [
            { tagId: 18, name: 'Vue.js基础', isList: true  },
            { tagId: 19, name: 'Vue.js进阶', isList: true },
            { tagId: 20, name: 'Vue.js综合案例', isList: true  },
          ] 
        },
        { 
          tagId: 9, 
          name: 'jQuery Web开发案例教程', 
          isList: true,
          children: [
            { tagId: 10, name: 'jQuery基础', isList: true  },
            { tagId: 11, name: 'jQuery进阶', isList: true },
            { tagId: 12, name: 'jQuery综合案例', isList: true  },
          ] 
        },
        { 
          name: '其他内容', 
          children: [
            { tagId: 21, name: '新书速递', isList: true  },
            { tagId: 23, name: '图书勘误', isList: true },
            { tagId: 22, name: '极客范(GeekFun)', isList: true  },
            { tagId: 24, name: '技术随笔', isList: true },
            { tagId: 25, name: '非技术随笔', isList: true },
            { tagId: 26, name: '写作与出版', isList: true },
          ]
        }
      ],
    }
  },
  watch: {
    $route: {
      handler() {
        const item = this.categories.find(ele => ele.tagId == this.$route.params.tagId)
        if (item == undefined) {
          let obj = null
          this.categories.forEach(ele => {
            console.log(ele)
            obj = ele.children.find(el => el.tagId == this.$route.params.tagId)
            if (obj) {
              document.title = obj.name + ' | 前沿技术社区'
            }
          })
        } else {
          document.title = '首页 | 前沿技术社区'
        }
        
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#sidebar-control {
  position: fixed;
  background: #fff;
  width: 80%;
  top: 50px;
  bottom: 0;
  overflow-y: auto;
  transition: right 0.5s;
  right: -80%;
  border-left: 1px solid $lineColor;
  padding-left: 20px;
  box-sizing: border-box;
  z-index: 1;

  &.active {
    right: 0;
  }
}

.post-sidebar {
  #sidebar-categories > li {
    margin: 12px 0;
  }
  ul {
    margin: 12px 0;

    ul {
      margin-left: 20px;

      h3 {
        font-size: 12px;
      }
    }
  }
  h2 {
    line-height: 2em;
  }
  h3, h2 {
    font-weight: normal;

    a {
      color: $secondaryColor;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  #sidebar-control {
    position: static;
    margin-top: 70px;
    width: 30%;
  }
}
</style>