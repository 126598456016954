<template>
  <div class="create-update-article-page">
    <section class="section-md" v-if="!articleObj.isImported">
      <div class="container container-sm container-lg container-xs">
        <ValidationObserver tag="div" ref="createUpdateObs" class="row row-50">
          <ValidationProvider
            class="mb-4 tip-wrapper"
            tag="div"
            name="文章标题"
            rules="required"
            v-slot="{ errors }"
          >
            <input
              type="text"
              v-model="articleObj.subject"
              class="form-control"
              placeholder="文章标题*"
            />
            <span class="tip-info">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            class="mb-4 tip-wrapper"
            tag="div"
            rules="required"
            name="文章内容"
            v-slot="{ errors }"
          >
            <quill-editor
              ref="richTextEditor"
              v-model="articleObj.content"
              :options="editorOption"
              @change="onEditorChange($event)"
              class="us-editor"
            ></quill-editor>
            <span class="tip-info">{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="tagsWrap mb-4">
            <div class="form-control flex">
              <span class="tags">
                <span v-for="(item, index) in articleObj.tags" :key="index">
                  {{ item }} <i class="fa fa-times" @click="deleteTagHandler(index)"></i>
                </span>
              </span>
              <input
                v-model="tag"
                @keyup="keyupHandler"
                @input="inputTags"
                autocomplete="off"
                placeholder="文章标签*"
                type="text"
                name="文章标签"
              />
            </div>
          </div>
          <!-- 头像 -->
          <div class="mb-4 avatar-wrap">
            <div class="avatar-wrap-top" v-if="articleObj.cover">
              <img class="mb-2" :src="cover" alt="" />
              <p @click="deleteArticleCoverHandler" title="删除封面" class="fa fa-times cursor"></p>
            </div>
            <input
              type="file"
              class="form-control"
              id="cover"
              accept="image/*"
              ref="fileEle"
              @change="uploadImg($event)"
            />
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="isPublish"
              v-model="articleObj.isPublished"
            />
            <label class="form-check-label" for="isPublish">是否发布</label>
          </div>
          <div class="operate-btn">
            <div v-if="articleId > 0">
              <div class="button button-primary" @click="updateArticleHandler">修改</div>
              <div class="button" @click="deleteArticleHandler">删除</div>
            </div>
            <div v-else class="button button-primary" @click="createArticleHandler">
              创建
            </div>
          </div>
        </ValidationObserver>
      </div>
    </section>
    <div v-else>不能编辑 {{articleObj.subject}}</div>
    <div v-if="!articleObj.isImported" class="quill-file-bar">
      <file-upload @insert="insert"></file-upload>
    </div>
  </div>
</template>
<script>
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';
  import 'quill/dist/quill.bubble.css';

  import axios from 'axios';
  import FileUpload from '@/components/Upload.vue'
  import { quillEditor } from 'vue-quill-editor';
  import Quill from 'quill';
  import BlotFormatter from '@chuyik/quill-blot-formatter';
  Quill.register('modules/blotFormatter', BlotFormatter);

  // 编辑器的toolbar配置项
  const toolbarOptions = [
    [{'header': [1, 2, 3, 4, 5, 6, false]}],
    ['bold', 'italic', 'underline', 'strike', { 'color': [] }, {'size': ['small', false, 'large', 'huge']}], 
    [{'list': 'ordered'}, {'list': 'bullet'}, 'blockquote', 'code-block'],
    [{'indent': '-1'}, {'indent': '+1'}, { 'align': [] }],
    ['link', 'image', 'video']
  ];

  export default {
    name: 'ArticleCreateUpdate',
    components: {
      quillEditor,
      FileUpload
    },
    data() {
      return {
        editorOption: {
          placeholder: '请输入文章内容 *',
          modules: {
            toolbar: toolbarOptions,
            blotFormatter: {}
          }
        },
        articleObj: {
          subject: '',
          abstract: '',
          content: '',
          tags: [],
          cover: '',
          isPublished: false,
          isImported: false
        },
        tag: '',
        articleId: -1,
        // 弹框信息
        isSuccessAlert: true,
        alertInfo: '',
        isShowAlert: false,
        quillFlag: false
      };
    },
    computed: {
      cover() {
        return this.$fileUrl(this.articleObj.cover)
      },
      editor() {
        return this.$refs.richTextEditor.quill
      }
    },
    created() {
      this.articleId = this.$route.params.articleId;
      this.feedbackArticleHandler(this.articleId);
    },
    methods: {
      insert(file) {
        const imageTypes = ['.png', '.jpg', '.gif', '.jpeg']
        const quill = this.editor
        const index = (quill.getSelection() || {}).index || quill.getLength()
        const path = this.$fileUrl(file.key)
        if (imageTypes.includes(file.ext.toLowerCase()))
          quill.insertEmbed(index, 'image', path, 'user')
        else
          quill.insertText(index, file.file_name, 'link', path)
      },
      onEditorChange(e) {
        if (e.html && !this.quillFlag) {
          this.onEditorReady(e.quill)
          this.quillFlag = true
        }
      },
      onEditorReady (editor) {
        // https://github.com/surmon-china/vue-quill-editor/issues/63
        let qlEditor = null
        for (let i = 0; i < editor.container.childNodes.length; i++) {
          if (editor.container.childNodes[i].className === 'ql-editor') {
            qlEditor = editor.container.childNodes[i]
            break
          }
        }
        if (qlEditor === null) return
        for (let i = 0; i < qlEditor.childNodes.length; i++) {
          if (qlEditor.childNodes[i].nextSibling && qlEditor.childNodes[i].nextSibling.className !== 'ql-syntax') {
            continue
          }
          if (qlEditor.childNodes[i].innerHTML === '<br>') {
            qlEditor.removeChild(qlEditor.childNodes[i])
          }
        }
      },
      // 删除文章
      deleteArticleHandler() {
        const _this = this;
        this.$ajax.post('/api/article/' + this.articleId + '/delete', '', 2, response => {
          if (response.status >= 200 && response.status < 300) {
            console.log(response);
            alert('删除成功！');
            // 删除成功之后，跳转到创建（依旧是当前页）
            _this.$router.push({
              name: 'ArticleCreateUpdate',
              params: {
                articleId: 0
              }
            });
            // 刷新页面，清空数据
            _this.$router.go(0);
          } else {
            console.log(response.message);
          }
        });
      },
      // 删除文章的封面
      deleteArticleCoverHandler() {
        this.articleObj.cover = ''
        this.$refs.fileEle.value = ''
      },
      // 回显文章信息
      feedbackArticleHandler(articleId) {
        const _this = this;
        if (articleId == 0) {
          return;
        }
        this.$ajax.get('/api/article/' + articleId, '', 2, response => {
          if (response.status >= 200 && response.status < 300) {
            console.log(response);
            const articleObj = response.data;
            _this.articleObj.subject = articleObj.subject;
            _this.articleObj.abstract = articleObj.abstract;
            _this.articleObj.content = articleObj.content;
            _this.articleObj.tags = articleObj.tags.map(ele => ele.name);
            _this.articleObj.cover = articleObj.cover;
            _this.articleObj.isPublished = true;
            _this.articleObj.isImported = articleObj.isImported;
          } else {
            console.log(response.message);
          }
        });
      },
      // 修改文章
      async updateArticleHandler() {
        const isValid = await this.$refs.createUpdateObs.validate();
        if (!isValid) {
          console.log('没有通过校验');
          return false;
        }

        const _this = this;
        this.$ajax.post(
          '/api/article/' + this.articleId + '/update',
          this.articleObj,
          2,
          response => {
            if (response.status >= 200 && response.status < 300) {
              console.log(response);
              alert('修改成功！');
              // 刷新页面
              _this.$router.go(0);
            } else {
              console.log(response.message);
            }
          }
        );
      },
      // 创建文章
      async createArticleHandler() {
        const isValid = await this.$refs.createUpdateObs.validate();
        if (!isValid) {
          console.log('没有通过校验');
          return false;
        }

        const _this = this;
        this.$ajax.post('/api/article/create', this.articleObj, 2, response => {
          if (response.status >= 200 && response.status < 300) {
            console.log(response);
            alert('创建成功！');
            // 刷新页面
            _this.$router.go(0);
          } else {
            console.log(response.message);
          }
        });
      },
      uploadImg(obj) {
        const _this = this;
        const file = obj.target.files[0];
        if (file) {
          // var reader = new FileReader(); //调用FileReader
          // reader.readAsDataURL(file); //将文件读取为 DataURL(base64)
          // reader.onload = function(evt) {
          //   //读取操作完成时触发。
          //   _this.cover = evt.target.result; //将img标签的src绑定为DataURL
          // };
          const formData = new window.FormData();
          formData.append('file', file);
          axios({
            method: 'post',
            url: process.env.VUE_APP_UPLOAD_URL,
            data: formData,
            headers: {
              'Content-Type':
                'multipart/form-data; boundary=----WebKitFormBoundaryn6qdFAbQis3EN5pA'
            }
          })
            .then(function(response) {
              console.log(response);
              _this.articleObj.cover = response.data.key ? response.data.key : '';
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          alert('上传失败');
        }
      },
      keyupHandler(e) {
        console.log(e);
        // console.log(e.target.selectionStart)
        console.log(e.target.selectionEnd);
        if (e && e.keyCode == 37) {
          //左键
          // console.log(this.selectionCursor)
          if (e.target.selectionEnd == 0) {
            // if (this.selectionCursor > 0 && this.selectionCursor < 2) {
            //   console.log('左键到头了')
            //   this.selectionCursor++
            // } else {
            //   this.selectionCursor = 1
            // }
          }
        }
        if (e && e.keyCode == 39) {
          console.log('右键');
        }
      },
      deleteTagHandler(index) {
        this.articleObj.tags.splice(index, 1);
      },
      // 检测输入空格，表示一个tag
      inputTags(e) {
        if (e.data == ' ' && e.target.value.replace(/\s/g, '')) {
          this.articleObj.tags.push(e.target.value.trim());
          // 清空输入框的内容
          this.tag = '';
        }
      }
    }
  };
</script>
<style lang="scss">
  .create-update-article-page {

    .container {
      width: 839px;
    }

    .row-50>* {
      margin-bottom: 50px;
    }

    .tip-wrapper span.tip-info {
      position: absolute;
      font-size: 14px;
      color: #df3028;
      font-weight: 400;
    }

    .ql-editor {
      min-height: 150px;
    }
    
    .mb-4 {
      margin-bottom: 30px;
    }
    .button {
      background-color: #f8f8f8;
      color: #000;
      margin-top: 20px;
      display: inline-block;
      border-radius: 3px;
      padding: 12px 35px;
      width: auto;
      cursor: pointer;
      font-size: 14px;
    }
    .form-control {
      display: block;
      width: 100%;
      font-weight: 400;
      line-height: 1.5;
      background-clip: padding-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 5px;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      font-size: 14px;
      color: #888;
      background-color: #f8f8f8;
      min-height: 60px;
      border: 1px solid #f8f8f8;
      padding: 15px;
      box-sizing: border-box;

      &.flex {
        display: flex;
      }
    }

    // checkbox的样式
    .form-check .form-check-input {
      margin-left: 0;
      width: 1em;
      height: 1em;
    }

    .form-check-input:focus {
      box-shadow: none;
    }

    .form-check-input:checked {
      background-color: #0d6efd;
      border-color: #0d6efd;
    }
    
    .form-check-input[type=checkbox] {
      border-radius: 0.25em;
    }

    .form-check-input:checked[type=checkbox] {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3 6-6'/%3E%3C/svg%3E");
    }

    input[type=checkbox] {
      margin-right: 5px;
    }

    .button.button-primary, .button:hover {
      background: $themeColor;
      color: #fff;
      display: inline-block;
      border-radius: 3px;
      padding: 12px 35px;
      width: auto;
      cursor: pointer;
      font-size: 14px;
    }

    .form-check-input {
      width: 1em;
      height: 1em;
      margin-top: 0.25em;
      vertical-align: top;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
      border: 1px solid rgba(0,0,0,.25);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
    }

    .form-control[type=file] {
      overflow: hidden;
      line-height: 46px;
    }

    textarea.form-control {
      height: 80px;
    }
    
    .tags {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: inline-flex;
        align-items: center;
        margin: 0 0 0 20px;
        border: 1px solid;
        padding: 0 5px;
        border-radius: 3px;
        font-size: 14px;
        line-height: 30px;

        i {
          margin-left: 5px;
        }
      }

      i {
        font-size: 18px;
        margin-left: 0;
        cursor: pointer;
      }
    }

    .tagsWrap {
      > .form-control {
        padding: 0;
      }

      input {
        flex: 1;
        min-height: 60px;
        border: none;
        background-color: transparent;
        border-left: none;
        padding: 0 15px;

        &:focus {
          outline: none !important;
        }
      }
    }

    .avatar-wrap {
      .avatar-wrap-top {
        display: flex;
      }

      img {
        max-width: 400px;
        max-height: 400px;
      }

      p {
        margin-left: 10px;
        font-size: 26px;

        &:hover {
          color: #000;
        }
      }
    }

    .operate-btn {
      .button {
        margin-top: 0;
      }

      .button + .button {
        margin-left: 20px;
      }
    }

    .phone {
      .avatar-wrap {
        img {
          max-width: 92%;
          max-height: 100%;
        }
      }
    }
  }
  .quill-file-bar {
    position: fixed;
    top: 300px;
    right: 200px;
  }
</style>
