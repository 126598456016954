<template>
  <div class="post-page">
    <a href="javascript:void(0);" v-if="page != 1 && page <= totalPage" @click="pageClick(page-1)">上一页</a>
    <span v-if="totalPage > 0">第 {{page}} 页 / 共 {{totalPage}} 页</span>
    <a href="javascript:void(0);" v-if="page < totalPage" @click="pageClick(page+1)">下一页</a>
  </div>
</template>

<script>
export default {
  name: 'Page',
  props: {
    page: {
      default: 1
    },
    totalPage: {
      default: 1
    }
  },
  watch: {
    page() {
      return this.page;
    }
  },
  methods: {
    // 点击分页
    pageClick(page) {
      this.$emit('page-handler', page);
    }
  },
}
</script>

<style lang="scss">
.post-page {
  margin: 36px 0;
  font-size: 14px;
  text-align: center;

  span {
    color: $lightDefaultColor;
  }

  a {
    color: inherit;

    &:first-child {
      margin-right: 15px;
    }

    &:last-child {
      margin-left: 15px;
    }

    &:hover {
      color: $themeColor;
      text-decoration: underline;
    }
  }
}
</style>