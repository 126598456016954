<template>
  <div id="app">
    <vue-header @showSideBar="showSideBarHandler" />
    <!-- 本页标题 -->
    <div class="breadcrumbs" v-show="$route.name == 'ArticleCreateUpdate'">
      <div class="container">
        {{this.$route.params.articleId > 0 ? '编辑' : '创建'}}文章
      </div>
    </div>
    <div class="container flex justify-content-space-between">
      <div class="main">
        <router-view />
      </div>
      <vue-sidebar v-show="$route.name != 'ArticleCreateUpdate'" :isShowSideBar="isShowSideBar" />
    </div>
    <div class="mask" v-show="isShowSideBar" @click="hideMaskHandler"></div>
    <vue-footer />
  </div>
</template>

<script>
import VueHeader from '@/components/Header.vue'
import VueSidebar from '@/components/SideBar.vue'
import VueFooter from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    VueHeader,
    VueSidebar,
    VueFooter
  },
  data() {
    return {
      isShowSideBar: false
    }
  },
  mounted() {
    
  },
  methods: {
    hideMaskHandler() {
      this.isShowSideBar = false
    },
    showSideBarHandler(isShowSideBar) {
      this.isShowSideBar = isShowSideBar
    },
    // 屏幕的宽
    // AdaptToStyle() {
    //   const device =
    //     window.innerWidth > 1024 ? 'pc' : window.innerWidth >= 768 ? 'pad' : 'phone';
    //   this.$store.commit('setDevice', device);
    // }
  }
}
</script>

<style lang="scss">
@import './assets/common.scss';

.us-editor {

  .ql-snow .ql-editor img {
    height: auto;
  }

  .ql-snow .ql-editor pre.ql-syntax {
    margin-bottom: 1.75em;
  }

  .ql-editor {
    line-height: normal;
    color: #1a1a1a;
    font-size: 16px;
    line-height: 1.6;

    p {
      margin: 1.75em 0;
    }

    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

  }
}

.info {
  &.quill-editor {    
    .ql-container {
      &.ql-snow {
        border: none;

        .ql-editor {
          padding: 0;
        }
      }
    }
  }
}

.post-loading {
  text-align: center;
}

.breadcrumbs {
  background: #f8f8f8;
  padding: 20px;
  color: #000;
  font-weight: 300;
  padding-top: 70px;
  font-size: 18px;
}

.mask {
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.161);
}

.main {
  width: 100%;
  padding-top: 50px;
}

.tag {
  margin-bottom: 0.5em;

  span + span:before {
    content: ' / '
  }
}

@media screen and (min-width: 768px) {
  .main {
    width: 70%;
    padding-top: 70px;
  }

  .breadcrumbs {
    padding: 20px 0;
    padding-top: 90px;
    font-size: 24px;
  }
}

</style>
