<template>
  <div class="comment-box">
    <div class="comment-loading flex-three" v-show="isLoading">
      <p>
        <i class="fa fa-spinner fa-spin"></i>
        稍等片刻正在发表评论...
      </p>
    </div>
    <div class="comment-form">
      <p class="comment-notes">
        <span>电子邮件地址不会被公开。必填项已用<span class="required">*</span>标注</span>
        <span v-show="isReply" @click="cancelReplyHandler" class="close-btn"><i class="fa fa-times"></i>取消回复</span>
      </p>
      <ValidationObserver tag="div" ref="createCommentObs">
        <ValidationProvider
          class="tip-wrapper"
          tag="p"
          name="评论内容"
          rules="required"
          v-slot="{ errors }"
        >
          <label for="comment">评论内容<span class="required">*</span></label>
          <textarea id="comment" rows="6" v-model="commentObj.content"></textarea>
          <span class="tip-info">{{errors[0]}}</span>
        </ValidationProvider>
        <ValidationProvider
          class="tip-wrapper"
          tag="p"
          name="昵称"
          rules="required"
          v-slot="{ errors }"
        >
          <label for="author">昵称<span class="required">*</span></label>
          <input type="text" id="author" v-model="commentObj.userName">
          <span class="tip-info">{{errors[0]}}</span>
        </ValidationProvider>
        <ValidationProvider
          class="tip-wrapper"
          tag="p"
          name="电子邮箱"
          rules="required|email"
          v-slot="{ errors }"
        >
          <label for="email">电子邮箱<span class="required">*</span></label>
          <input type="text" id="email" v-model="commentObj.email">
          <span class="tip-info">{{errors[0]}}</span>
        </ValidationProvider>
        <p><span class="submit-btn" @click="createCommentHandler">发表评论</span> <span id="comment-error"></span></p>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Comments',
  props: {
    isReply: {
      type: Boolean,
      default: false
    },
    parentId: {
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      commentObj: {
        appName: 'article',
        objectId: 0,
        content: '',
        email: '',
        userName: '',
        parentId: null
      }
    };
  },
  methods: {
    // 发表评论
    async createCommentHandler() {
      const isValid = await this.$refs.createCommentObs.validate();
      if (!isValid) {
        console.log('没有通过校验');
        return false;
      }
      console.log('通过了')

      this.isLoading = true

      this.commentObj.parentId = this.parentId
      this.commentObj.objectId = Number(this.$route.params.articleId)
      console.log(this.commentObj)
      const _this = this
      this.$ajax.post('/api/comment/create', this.commentObj, 2, response => {
        // 无论成功或是失败，都将“评论中~”的提示信息去掉
        _this.isLoading = false
        if (response.status >= 200 && response.status < 300) {
          // 清空重置数据
          _this.commentObj = {
            appName: 'article',
            objectId: 0,
            content: '',
            email: '',
            userName: '',
            parentId: null
          };
          // 清空错误提示
          _this.$refs.createCommentObs.reset();
          // 评论成功
          if (_this.isReply) {
            console.log('回复成功')
            this.cancelReplyHandler()
          } else {
            console.log('评论成功')
          }
          console.log('刷新评论列表')
          this.$emit('refreshComments')
        }
      })

    },
    // 取消回复
    cancelReplyHandler() {
      this.$store.commit('setCurrentCommentId', 0) 
    }
  }
}
</script>

<style lang="scss">
.comments {
  margin-top: 30px;

  &.reply-form {
    border-top: 3px solid $secondaryColor;
    .comment-box {
      .comment-form {
        padding-top: 12px;
      }
    }
  }

  .title {
    font-size: 18px;
    border-top: 3px solid $secondaryColor;
    line-height: 2em;
  }

  .comment-box {
    position: relative;

    .comment-form {
      padding-top: 1.75em;

      .tip-wrapper {
        position: relative;

        .tip-info {
          position: absolute;
          font-size: 13px;
          color: $themeColor;
          display: block;
        }
      }

      .comment-notes {
        color: $lightDefaultColor;
      }

      .close-btn {
        // display: none;
        color: $themeColor;
        float: right;
        cursor: pointer;

        &:hover {
          text-decoration: line-through;
        }
      }
    }

    p {
      margin-bottom: 1.75em;
    }

    label {
      color: $lightDefaultColor;
      display: block;
      font-size: 13px;
      letter-spacing: 0.08em;
      line-height: 1.6;
      margin-bottom: 0.5em;
    }

    textarea,
    input[type="text"] {
      background: #f7f7f7;
      background-image: -webkit-linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0));
      border: 1px solid $darkLineColor;
      border-radius: 2px;
      color: $lightDefaultColor;
      padding: 0.6em 0.4em;
      width: 100%;
      box-sizing: border-box;
    }

    .submit-btn {
      background: $blackBtnColor;
      color: #fff;
      font-weight: 700;
      letter-spacing: 0.04em;
      line-height: 1;
      padding: 0.8em 0.8em 0.7em;
      cursor: pointer;
      border-radius: 2px;
      display: inline-block;

      &:hover {
        background: $themeColor;
      }
    }

  }

  #comment-error {
    color: $errorColor;
    font-size: 12px;
    margin-left: 20px;
  }

  .comment-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255,0.5);
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    justify-content: center;
  }

  .required {
    color: $themeColor;
  }
}
</style>